div .modalButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 15px -15px 0;
}

.btn-cleanFiltersModalFilter {
  font: Medium 12px/15px Montserrat;
  color: #fa6f6f;
  opacity: 1;
  background-color: transparent;
  border: none;
  margin-right: 10px;
}

.btn-cleanFiltersModalFilter:hover {
  font: Medium 12px/15px Montserrat;
  color: #fa6f6f;
  opacity: 1;
  background-color: transparent;
  border: none;
  margin-right: 10px;
}

.btn-filterModalFilter {
  height: 42px;
  font: SemiBold 12px/15px Montserrat;
  color: #ffffff;
  background: #5339b5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.btn-filterModalFilter:hover {
  height: 42px;
  font: SemiBold 12px/15px Montserrat;
  color: #ffffff;
  background: #5339b5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.containerBottomModalFilters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.rowBottomModalFilter {
  display: flex;
  justify-content: flex-end;
}
