.container__payment {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 10px;
}

.container__payment__top .valor {
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
  margin-bottom: 15px;
}

.container__payment__bottom {
  display: flex;
  align-items: center;
  margin: 0 5px 10px;
}

.container__payment__bottom > span {
  margin-right: 10px;
  font-weight: 400;
}

