.registerUsers__header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  margin-bottom: 1rem;
}

.row__fields__form {
  display: block;
}
