@media (max-width: 1000px) {
  #SyncDrawer .ant-radio-button-wrapper:nth-child(2) {
    border-left: 1px solid #d9d9d9;
    border-radius: 0px 6px 6px 0px;
  }
  #SyncDrawer .ant-radio-button-wrapper:nth-child(3) {
    border-left: 1px solid #d9d9d9;
    border-radius: 6px 0 0 6px;
  }
}
