.product-content {
  height: 100%;
  overflow: hidden;
  padding: 0px 15px 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-content .content-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.product-content .btn__end {
  margin-top: 20px;
}

.product-search {
  display: flex;
  width: 100%;
}

.tb-products {
  width: 100%;
}

.tb-products .ant-table-expanded-row {
  display: none;
}

.button-register {
  color: #5339b5 !important;
  background: #ffffff !important;
}

.col-title-formProduct {
  margin: 0 0 15px 5px;
}

.product-pagination {
  margin-bottom: 5px;
  margin-top: 5px;
}
