.buttonVariationBlock {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.validationError {
    background-color: #FFF1EF;
    padding: 10px;
    border: 1px solid #ffccc7;
}