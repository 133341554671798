* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input,
button {
  outline: 0;
  border: 0;
}

.conteiner {
  height: auto;
  position: relative;
  z-index: 1;
}

.conteiner .content_product {
  margin-bottom: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn_newProduct {
  border-radius: 8px !important;
  opacity: 1 !important;
  height: 42px !important;
  font: SemiBold 12px/15px Montserrat !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
}

.btn_newProduct span {
  margin-left: 10px;
}

.content_tableFiltersPagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.content_tableFilters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.btn_filter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background: #c5d2f7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  margin-left: 10px;
  margin-bottom: 15px;
}

.input_search {
  height: 35px;
}

.pgContent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

button:disabled {
  opacity: 0.4;
}

.search {
  width: 100%;
}

.table-products {
  margin-top: 10px;
}

.containerSearch {
  display: flex !important;
}

.rowTags {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.containerTags {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  margin-left: 10px;
}

.containerPagination {
  display: flex !important;
  justify-content: flex-end !important;
}

.selected-row > td {
  box-shadow: 0 0 6px rgba(35, 173, 278, 1) !important;
}

.columnIcon {
  padding: 2px 2px 2px 2px;
  margin: 0px 0px 0px 20px;
}

.container-actions-tr {
  opacity: 0.2;
}

.container-actions {
  position: absolute;
  top: 0;
  left: 0;
  right: 25px;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2%;
}

#ListScreenActions .ant-popover-inner-content {
  height: 50px;
}

#ListScreenActions.width-0x .ant-popover-inner-content {
  width: 250px;
}

#ListScreenActions.width-1x .ant-popover-inner-content {
  width: 202px;
}

#ListScreenActions.width-2x .ant-popover-inner-content {
  width: 155px;
}
