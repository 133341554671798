.recipe_delete_icon,
.recipe_edit_icon,
.recipe_view_icon {
  color: var(--primary-blue);
}
.recipe_delete_icon:hover,
.recipe_delete_icon:focus {
  color: red;
}

.recipe_edit_icon,
.recipe_view_icon {
  margin-right: 5px;
}

.tb__recipe {
  width: 100%;
}
