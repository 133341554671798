.container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #fefefe;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  text-decoration: none;

  right: 1rem;
  top: 0.7rem;
}

.container:hover,
.container:focus {
  cursor: pointer;
  border: 1px solid var(--primary-blue);
}

.icon {
  position: relative;
}
