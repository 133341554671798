.customer-content {
  height: 60vh;
  padding: 0px 15px 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customer-search {
  display: flex;
  width: 100%;
}

.customer-search .input_search {
  width: 60%;
  flex: 1;
}

.tb-customers {
  height: 45vh !important;
  width: 100%;
  display: flex;
}

.tb-customers > div {
  flex: 1;
}

.tb-customers td.ant-table-cell[colspan='3'] {
  display: none;
}

.button-register {
  color: #5339b5 !important;
  background: #ffffff !important;
}

.col-title-formCustomer {
  margin: 0 0 10px 5px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.customer-pagination {
  margin-bottom: 5px;
  margin-top: 5px;
}
