.tablePayments {
  width: 100%;
  margin: 0 5px 15px;
}

@media (max-width: 450px) {
  .tablePayments {
    width: 97%;
    margin: 100px 5px 15px;
  }
}
