.customer-preview-content {
  height: 100%;
}

.customer-preview-content .ant-form-vertical {
  overflow-y: auto;
}

.customer-preview-content .row-cliente-preview {
  height: 93%;
  display: flex;
  justify-content: space-between;
}

.row-cliente-preview .col-cliente-preview {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.col-cliente-preview label {
  font-weight: 500;
}

.col-cliente-preview span {
  display: flex;
}

.col-cliente-preview span#name::before {
  content: "-";
  color: #FFFFFF;
  background-color: #747C92;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  padding-bottom: 7px;
  border-radius: 5px;
  text-align: center;
}

.row-cliente-preview .col-btn-edit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-cliente-preview .col-btn-edit strong {
  flex: 1;
}

.row-cliente-preview .col-btn-edit .edit__person {
 margin-left: 10px;
 height: 25px;
 width: 25px;
 text-align: center;
}

.col-btn-edit .ant-btn {
  margin: 0;
}

.customer-preview-content .title {
  margin: 0 0 13px !important;
}

.customer-preview-content form .title {
  margin: 0 0 15px 5px !important;
}

.customer-preview-content .ant-form {
  overflow-y: scroll;
  height: 60vh !important;
  display: block;
}

.customer-preview-content .row-btns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.row-btns [type=button] {
  width: 45%;
}

.row-btns .button-cancel {
  color: red !important;
  border-color: red !important;
  background: #FFFFFF !important;
  margin-left: 6px;
}
