.ant-collapse {
  background-color: white;
}

.content__orderPreview {
  display: block;
  height: 44vh;
  overflow-y: auto;
  padding: 3px;
}

.headerPreview {
  margin-bottom: 25px;
}

.headerPreview span {
  font-size: 16px;
  font-weight: 500;
}

.headerPreview__icon {
  margin-right: 10px;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none;
}

.collapse__worker .ant-collapse-header {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  font-weight: 500;
}

.collapse__worker .ant-collapse-content-box {
  padding: 0 !important;
}

.collapse__worker .ant-collapse-extra {
  display: contents;
}

.collapse__worker__header {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-weight: normal;
}

.collapse__worker__header img {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border: 1px solid #3a2449;
  border-radius: 50%;
}

.collapse__worker__storeAndPlatform {
  padding-top: 20px;
}

.collapse__worker__storeAndPlatform span:first-child {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.collapse__divider {
  margin: 20px 0;
  border: 1px solid #c5d2f7;
}

.content__footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 35px;
}

.totals {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
}

.totals__title {
  display: flex;
  flex-direction: column;
}

.totals__value {
  display: flex;
  flex-direction: column;
}

.totals__title :not(:last-child),
.totals__value :not(:last-child) {
  font-size: 11px;
  color: #9ca8c4;
  margin-bottom: 5px;
}

.totals__value :not(:last-child) {
  text-align: right;
}

.totals__title span:last-child,
.totals__value span:last-child {
  font-size: 15px;
  color: #3a2449;
  margin-top: 10px;
}

@media (max-width: 450px) {
  .content__orderPreview {
    height: 375px !important;
    margin-bottom: 120px;
  }
}

@media (max-height: 780px) {
  .content__orderPreview {
    height: 33vh;
  }
}

@media (max-width: 780px) {
  .content__orderPreview {
    height: 375px !important;
    margin-bottom: 120px;
  }
}
