.container__contacts,
.tb__contacts {
  width: 100%;
}

.container__contacts .add__contact {
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
}
