.containerPedido {
  width: 100%;
  height: 1129px;
  padding: 30px 50px 74px 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c5d2f7;
  border-radius: 10px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.headerBtn {
  width: 139px;
  border: 1px solid #fa6f6f;
  border-radius: 8px;
  background: #fff;
  color: #fa6f6f;
}

.headerBtn:hover,
.headerBtn:focus {
  background: #fff;
  border-color: #fa6f6f;
  color: #fa6f6f;
  box-shadow: 0 0 5px #fa6f6f;
}

.span {
  color: green;
}

.platBtn {
  background: #fedfdf;
  color: #fa6f6f;
  border: none;
}

.platBtn:hover,
.platBtn:focus {
  background: #fff;
  border-color: #fa6f6f;
  color: #fa6f6f;
  box-shadow: 0 0 5px #fa6f6f;
}

.creditCard::before {
  content: '';
  display: inline-block;
  width: 25px;
  height: 15px;
  border-radius: 50%;
  background: url('../../assets/images/1200px-Mastercard_2019_logo.svg.png') no-repeat center center;
  margin-right: 5px;
}

.mt-1 {
  margin-top: 1rem !important;
}

p {
  color: #5e6474;
}
