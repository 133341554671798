.modal__selectPerson .btn__search__person {
  display: flex;
}

.modal__selectPerson .btn__search__person > button {
  flex: 1;
}

.modal__selectPerson .divider {
  margin: 20px 5px;
}

.modal__selectPerson .table {
  margin: 0 5px;
}

.modal__selectPerson .col__pagination {
  margin-bottom: 5px;
}

.modal__selectPerson .col-btns {
  margin-top: 15px;
}
