* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input,
button {
  outline: 0;
  border: 0;
}

.container {
  height: auto;
  position: relative;
  z-index: 1;
}

.container .header {
  margin-bottom: 20px;
  padding: 0;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

