.title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 1.5rem;
}

.children {
  display: flex;
  gap: 0.5rem;
}
