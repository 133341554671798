div .modalButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 15px -15px 0;
}

.btn-cancel {
  width: 100px;
  height: 42px;
  font: Medium 12px/15px Montserrat;
  color: #5339B5;
  opacity: 1;
  padding: 0px;
  background-color: transparent;
  border-color: #5339B5;
  width: 100%;
  margin-right: 10px;
}


.btn-cancel:hover {
  font: Medium 12px/15px Montserrat;
  color: #5339B5;
  opacity: 1;
  padding: 0px;
  background-color: transparent;
  border-color: #5339B5;
  width: 100%;
  margin-right: 10px;
}

.btn-filter {
  width: 100px;
  height: 42px;
  font: SemiBold 12px/15px Montserrat;
  color: #FFFFFF;
  background: #5339B5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  width: 100%;
}

.btn-filter:hover {
  width: 100px;
  height: 42px;
  font: SemiBold 12px/15px Montserrat;
  color: #FFFFFF;
  background: #5339B5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  width: 100%;
}

.containerBottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 8% !important;
  padding-right: 8% !important;
  margin-top: 20px;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.description {
  text-align: center;
}