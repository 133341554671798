* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input,
button {
  outline: 0;
  border: 0;
}

.container {
  height: auto;
  position: relative;
  z-index: 1;
}

.container .header {
  margin-bottom: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn_group button:first-child {
    margin-right: 20px;
}

.btn_newEmployee span {
  margin-left: 10px;
}

.avatar {
  padding: 40px 0px 40px 1rem;
}

.table_input {
  margin-bottom: 0px;
  margin-top: 10px;
}

.btn_table {
  margin-left: 20px;
}

.module {
  display: flex;
  justify-content: center;
}

.btn_module {
  margin-bottom: 10px;
}

.sub.btn_module {
  width: 98%;
  margin-left: 2%;
}

.btn_module:hover {
  background-color: #1AAE9F;
  color: white;
}

.check_module {
  position: absolute;
  left: 10px;
}