.title {
  display: block;
  text-align: center;
}

.radio {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

@media (max-width: 1000px) {
  .radio {
    display: grid;
    grid-template-columns: 1fr 1fr;

    padding: 0 0.5rem;
  }
}
