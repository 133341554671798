.content-product-modal .left {
  height: 100%;
  margin-right: 15px;
}

.content-product-modal .left .sm-photos {
  width: 100%;
  display: -webkit-box;
  gap: 5px;
  overflow-x: auto;
  margin-bottom: 10px;
}

.content-product-modal .left .sm-photos::-webkit-scrollbar {
  height: 5px;
}

.content-product-modal .left .sm-photos::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px #5339b5;
  background: rgba(0, 0, 0, 0.2);
}
.content-product-modal .left .sm-photos::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.content-product-modal .left .sm-photos .photo {
  width: 50px;
  height: 50px;
  border-radius: 6px;
}

.content-product-modal .left .sm-photos .photo.selected {
  border: 2px solid #c5d2f7;
}

.content-product-modal .left .main-photo {
  height: 250px;
  border-radius: 6px !important;
}

.content-product-modal .right {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 780px) {
  .right .brand {
    margin-top: 15px;
  }
}

.content-product-modal .right .brand {
  color: #fa6f6f;
  margin-bottom: 5px;
}

.content-product-modal .right .name {
  font-size: 18px;
  margin-bottom: 20px;
}

.content-product-modal .right .sku {
  margin: 5px 0 20px;
}

.content-btns-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-btns-actions .btn {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.content-btns-actions .btn span {
  font-size: 12px;
  margin-right: 5px;
  margin-left: 5px;
}
