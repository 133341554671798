.container__address,
.tb__address {
  width: 100%;

}

.container__address .add__address {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
}
