.line_blue {
  color: var(--primary-blue);
}

.line_red {
  color: red;
}

.line_red:focus,
.line_red:hover {
  border-color: red;
}

.spherical_col:first-child {
  padding-right: 4% !important;
}

.inline_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 10px;
}

.tb__recipe_view {
  width: 100%;
}
