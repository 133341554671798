* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input, button {
  outline: 0;
  border: 0;
}

.containerScrollRegisteredProducts {
  overflow-y: auto;
  height: 120px;
}