.container-variations {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}

.content-variations {
  margin-top: 5px;
  border: 1px solid #c5d2f7;
  border-radius: 5px;
}

.content-variations .top {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c5d2f7;
}

.top img {
  width: 40px;
  height: 35px;
  margin: 2px 0 2px 2px;
  border-radius: 5px;
}

.top span {
  display: block;
  font-weight: bold;
  font-size: 10px;
  padding-left: 5px;
}

.bottom > div {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.bottom > div .ant-btn {
  background-color: #ffffff;
  border: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 11px;
  height: 25px;
}

.bottom > span {
  display: flex;
  background: yellow;
  justify-content: center;
  border-radius: 0 0 7px 7px;
}
