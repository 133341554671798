@media only screen and (max-width: 800px) {
  .view-card {
    margin-top: 5px;
  }
  .tabs-content .col-card {
    margin-bottom: 10px !important;
  }
}

.ant-form.ant-form-vertical {
  height: 100%;
}

.view-card {
  height: 97.5%;
  display: block;
}

.view-card .ant-card-body {
  height: 65vh;
  display: block;
}

.row-content-orders {
  height: 97.5%;
  overflow-y: hidden;
}

@media (max-width: 450px) {
  .view-card .ant-card-body {
    height: 610px;
  }
}
