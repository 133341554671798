.container__delivery {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 15px;
  overflow-y: auto;
}

.container__delivery__bottom {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.container__delivery .ant-card {
  margin-bottom: 0px !important;
}

.container__delivery__top__step2 {
  display: flex;
  flex-direction: column;
}

.container__delivery__top__step2 > div > span {
  background-color: transparent;
  border: 0;
  color: #5339b5;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  position: relative;
  text-decoration: underline;
  margin-left: 5px;
  margin-bottom: 20px;
}
