.container__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.container__item__left {
  display: flex;
  align-items: center;
  flex: 1;
}

.container__item__left:hover {
  opacity: 0.5;
  cursor: pointer;

  border: 1px #5339b5 dashed;
}

.container__body_items {
  height: 300px;
  overflow-y: auto;
}

.container__item__left img {
  width: 40px;
  height: 35px;
  border: 1px solid #c5d2f7;
  border-radius: 5px;
}

.container__item__left span {
  font-size: 12px;
  margin-left: 2px;
  flex: 1;
}

.container__item__left__variation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container__item__left__variation .content {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.container__item__left__variation .content > span {
  margin-right: 3px;
}

.container__item__left__variation .content > div > span {
  font-weight: 500;
}

.container__item__left__variation .price {
  display: flex;
  flex-direction: column;
}

.container__item__left__variation .price span:first-child {
  color: red;
  text-decoration: line-through;
  font-size: smaller;
}

.container__item__left__variation .price span:nth-child(2),
.container__item__left__variation div:last-child {
  font-size: small;
}

.container__item__right .ant-btn-link {
  padding: 0 5px !important;
}

.content__actions {
  display: flex;
  flex-direction: column;
}
