.mainlyContainer {
    background-color: #f5f5f5;
  }
  .header {
    height: 50px;
  }
  .containerLogin {
    border-radius: 10px;
    margin-top: 20px;
    box-shadow: 5px 5px 18px #888888;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .containerLogin label#login {
    margin-left: 15px;
  }
  
  .containerForm {
    margin-top: 20px;
  }
  .input {
    margin-bottom: 10px;
    border-color: black !important;
  }
  .containerFormActions__login {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
  }
  .containerFormActionsPasswordRecovery {
    display: flex;
    margin-top: 15px;
    justify-content: flex-end;
  }
  .forgotPassword {
    padding: 0;
  }
  