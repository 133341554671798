.colBtnSearch {
  margin: 0px 5px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.colBtnSearch button {
  flex: 1;
}

.divider {
  margin: 0 7px;
}

.status {
  margin: 20px 5px 0px;
}

.status div span {
  font-weight: 500;
  margin-right: 5px;
}

.status div svg {
  margin: 0 0 2px 5px;
}

.statusErrors {
  display: inline-flex;
  font-weight: normal !important;
  max-height: 100px;
  overflow-y: auto;
}
