/* .ant-table-middle .ant-table-scroll {
    height: 240px;
  }

  .ant-menu-submenu-title {
    color: white !important;
  }
  .ant-menu-submenu-arrow{
    display: none;
  }
  .ant-menu-item .ant-menu-item-selected {
    color: #fa8c15  !important;
  }

  .ant-menu-dark .ant-menu-item {
    color: white !important;
  }

  .ant-menu-dark .ant-menu-item > a {
    color: white !important;
  }

  .gx-layout-sider-dark {
    background-color: #003366;
    color: white !important;
  }
  .ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > a:hover {
    color: #fa8c15  !important;
  }
  .gx-sidebar-notifications {
    padding: 30px 10px 10px;
    margin: 0 20px 10px;
    border-bottom: solid 1px #e8e8e8 !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #003366 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #003366 !important;
}
.ant-menu-item > a {
    color: #fff !important;
}
.ant-menu-item-selected > a, .ant-menu-item-selected > a:hover {
    color: #038fde !important;
}
.ant-menu-item > a {
    display: block;
    color: #fff !important;
}
.ant-menu {
    background-color: #003366 !important;
}

.ant-menu-item-selected a {
  color: #038fde !important;
}

.ant-col {
  padding: 0px 5px !important;
}



.ant-btn {
  margin-left: 5px !important;
}

.ant-legacy-form-item {
  margin-bottom: 5px !important;
}

.ant-legacy-form label {
  font-size: 12px !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
}

.ant-tabs-nav-list button {
  width: 50%;
  margin: 0 !important;
}

.content-tabPane {
  padding: 10px !important;
} */

:root {
  --primary-blue: #5339b5;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  background: #ccc;
  padding: 2px;
}

.required {
  color: red;
}

.search__indicationPerson {
  cursor: pointer !important;
}
.search__indicationPerson input {
  cursor: pointer !important;
}

.btn__in_popover {
  margin: 0 !important;
  padding: 0 !important;
  display: block !important;
}

.ant-popover-content,
.ant-modal-body {
  max-height: 70vh;
}

.ant-modal-body {
  overflow: auto;
}
