.view-tokens {
    display: flex;
    justify-self: flex-end;
}

.row {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.custom-text-area {
    min-height: 70px !important;
}