.item {
  padding: 1rem;
  margin: 1rem 0.5rem;

  border: 1px solid #f0f0f0;
  border-radius: 6px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'name name' 'platform status';
  align-items: center;
}

.status {
  grid-area: status;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status span {
  align-self: baseline;
}

.name {
  grid-area: name;
}

.platform {
  grid-area: platform;
}

.platform img {
  height: 1.5rem;
  width: 2rem;
}
