div .modalButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 15px -15px 0;
}

.btn-cleanFiltersModalFilter {
  font: Medium 12px/15px Montserrat;
  color: #fa6f6f;
  opacity: 1;
  background-color: transparent;
  border: none;
  margin-right: 10px;
}

.btn-cleanFiltersModalFilter:hover {
  font: Medium 12px/15px Montserrat;
  color: #fa6f6f;
  opacity: 1;
  background-color: transparent;
  border: none;
  margin-right: 10px;
}

.btn-filterModalFilter {
  height: 42px;
  font: SemiBold 12px/15px Montserrat;
  color: #ffffff;
  background: #5339b5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.btn-filterModalFilter:hover {
  height: 42px;
  font: SemiBold 12px/15px Montserrat;
  color: #ffffff;
  background: #5339b5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.containerBottomModalFilters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.rowBottomModalFilter {
  display: flex;
  justify-content: flex-end;
}


.upload__content {
  padding: 0px 5px;
}

.upload__title {
  margin-bottom: 5px;
}

.upload__component {
  display: flex;
  flex-wrap: wrap;
}

.upload__component > div {
  display: contents;
  flex-grow: 1;
  margin-right: 0 !important;
}

.btn__upload--icon {
  color: #5339b5;
}

.btn__upload--txt {
  color: #5339b5;
}

.containerImg {
  display: flex;
    justify-content: center;
   
    border-width: 1px;
    border-radius: 12px;
}

.imgUpload {
  object-fit: cover;
  border-radius: 15px;
  height: 150px;
  width: 100%;
}

.containerUploadColumn {
  padding: 5px 5px 5px 5px;
}

.containerActions {
  position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    padding-left: 10px;
    justify-content: flex-end;
    padding-right: 10px;
}

.iconHome {
  background-color: #747C92;
  padding: 5px 5px 5px 5px;
  border-radius: 25px;
}
.iconDelete {
  background-color: #FA6F6F;
  padding: 5px 5px 5px 5px;
  border-radius: 25px;
}
.selectedImage {
  background-color: #386AF2;
}

.loadingContainer {
  border-radius: 15px;
  height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.selectedImage {
    border-width: 1px;
    border-style: dotted;
}
div .modalButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 15px -15px 0;
}

.btn-cleanFiltersModalFilter {
  font: Medium 12px/15px Montserrat;
  color: #fa6f6f;
  opacity: 1;
  background-color: transparent;
  border: none;
  margin-right: 10px;
}

.btn-cleanFiltersModalFilter:hover {
  font: Medium 12px/15px Montserrat;
  color: #fa6f6f;
  opacity: 1;
  background-color: transparent;
  border: none;
  margin-right: 10px;
}

.btn-filterModalFilter {
  height: 42px;
  font: SemiBold 12px/15px Montserrat;
  color: #ffffff;
  background: #5339b5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.btn-filterModalFilter:hover {
  height: 42px;
  font: SemiBold 12px/15px Montserrat;
  color: #ffffff;
  background: #5339b5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.containerBottomModalFilters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.rowBottomModalFilter {
  display: flex;
  justify-content: flex-end;
}
