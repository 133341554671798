.card__address .ant-card-body {
  background-color: #f1f3fb;
  border-radius: 6px;
  border: 1px solid #c5d2f7;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card__address__info,
.card__address__actions {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card__address__actions {
  align-items: flex-end;
}

.card__address__info strong:first-child {
  font-size: 15px;
  margin-bottom: 10px;
}

.card__address__info strong:nth-child(2),
.card__address__info span {
  font-size: 13px;
  margin-bottom: 5px;
}

.card__address__info span {
  width: 300px;
}

.card__address__actions button:first-child {
  width: 140px;
  height: 40px;
  border-radius: 8px;
  border: 2px solid #5339b5;
  color: #5339b5;
  margin-bottom: 10px;
  background-color: #f1f3fb;
}

.card__address__actions button:nth-child(2) {
  width: 140px;
  height: 40px;
  border-radius: 8px;
  border: 2px solid #fa6f6f;
  color: #fa6f6f;
  background-color: #f1f3fb;
}

@media (max-width: 450px) {
  .card__address .ant-card-body {
    flex-direction: column;
  }
  .card__address__actions {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
  }
  .card__address__actions button:first-child,
  .card__address__actions button:nth-child(2) {
    width: 120px;
    margin-bottom: 0;
  }
  .card__address__info span {
    width: 100%;
  }
}
